import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Main from '../components/Main'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        locale: frJson {
          header {
            title
            introduction
            menu
          }
          articles {
            title
            text
          }
          contact {
            title
            name
            email
            message
            send
            reset
            sociaux {
              name
              url
              icon
            }
          }
          footer {
            copyright
          }
        }
      }
    `}
    render={data => <Main lang="fr" data={data.locale} />}
  />
)
